.customBadge {
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-padding-start: var(--chakra-space-1);
  padding-inline-start: var(--chakra-space-1);
  -webkit-padding-end: var(--chakra-space-1);
  padding-inline-end: var(--chakra-space-1);
  text-transform: uppercase;
  border-radius: var(--chakra-radii-sm);
  font-weight: var(--chakra-fontWeights-bold);
  background: var(--badge-bg);
  color: var(--badge-color);
  box-shadow: var(--badge-shadow);
  --badge-bg: var(--chakra-colors-blue-100);
  --badge-color: var(--chakra-colors-blue-800);
  font-size: 16px;
}
